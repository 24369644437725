import { getCurrencyCountryCode } from '@commerce/utils'
import { Button, useUI } from '@components/ui'
import { newsletterSubscriptionComplete } from '@lib/events/newsletterSubscriptionComplete'
import cn from 'classnames'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import React, { FC, useRef, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

interface Props {
  listID: string
  listSource?: string
  style?: 'column'
  showError?: boolean
  setSubmissionSuccess?: (arg: boolean) => void
  collect_mobile_number?: boolean
}

const SubscribeForm: FC<Props> = ({
  listID,
  listSource,
  style,
  showError = false,
  setSubmissionSuccess,
  collect_mobile_number = false,
}) => {
  const { locale } = useRouter()
  const [email, setEmail] = useState('')
  const [phone_number, setPhoneNumber] = useState<any>(undefined)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [validEmail, setValidEmail] = useState<boolean | null>(null)
  const { formFocused, formUnfocused } = useUI()
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  const timeout = useRef(setTimeout(() => {}))

  const defaultCountry: any = getCurrencyCountryCode(locale)

  const debounce = (func: Function, wait: number) => {
    clearTimeout(timeout?.current)
    timeout.current = setTimeout(() => {
      func()
    }, wait)
  }

  const handleEmailVerification = (email: string) => {
    if (!email) {
      setValidEmail(null)
      return
    }

    if (!email.match(regex)) {
      setValidEmail(false)
    } else {
      setValidEmail(true)
    }
  }

  const handleOnChange = (e: React.SyntheticEvent<EventTarget>) => {
    const value = (e.target as HTMLInputElement).value
    value === '' ? setValidEmail(null) : null
    setEmail(value)
    debounce(() => {
      handleEmailVerification(value)
    }, 1000)
  }

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.type === 'focus') {
      formFocused()
    }

    if (e.type === 'blur') {
      formUnfocused()
    }
  }

  const handleSubscription = async () => {
    setLoading(true)
    setError(false)
    setMessage('')
    if (email && email.match(regex)) {
      try {
        const response = await fetch('/api/klaviyo/subscribe', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, listID, locale, phone_number, source: listSource }),
        })
        const result = await response.json()
        if (result === 1) {
          //setMessage('You have been successfully subscribed')
          setLoading(false)
          setSuccess(true)
          newsletterSubscriptionComplete(email)
          if (setSubmissionSuccess) setSubmissionSuccess(true)
        } else {
          setError(true)
          setMessage('There was an issue subscribing you. Please try again later')
          setLoading(false)
        }
      } catch (err) {
        setError(true)
        setMessage('There was an issue subscribing you. Please try again later')
        setLoading(false)
      }
    } else {
      setError(true)
      setMessage('Please enter a valid email')
      setLoading(false)
    }
  }

  const renderChildren = () => (
    <>
      <div className="relative col-span-2">
        <input
          className={cn('col-span-2 px-12 bg-transparent focus:outline-none body text-black rounded-2 w-full', {
            'border border-black': email !== '',
            'border border-charcoal': email === '',
            'border border-errorRed': validEmail === false && email,
          })}
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnFocus}
        />
        <div className="absolute right-16 top-16">
          {validEmail === true && email && (
            <Image src={`/${locale}/green-tick.svg`} alt="Success" height={18} width={18} />
          )}
          {validEmail === false && email && (
            <Image src={`/${locale}/red-cross.svg`} alt="Fail" height={18} width={18} />
          )}
        </div>

        {showError && validEmail === false && email && (
          <div className="body text-errorRed">Enters a valid email address</div>
        )}
      </div>
      {collect_mobile_number ? (
        <div className="relative flex-grow tablet:flex-grow-0">
          <div className="border h-48 rounded-2 py-12 px-16 bg-transparent focus:outline-none body text-black tablet: w-auto ">
            <PhoneInput
              placeholder="Enter your Phone Number"
              value={phone_number}
              onChange={setPhoneNumber}
              defaultCountry={defaultCountry}
            />
          </div>
        </div>
      ) : null}
      <Button
        style={{
          whiteSpace: 'nowrap',
          borderTopLeftRadius: listSource === 'footer' ? '0' : '24px',
          borderBottomLeftRadius: listSource === 'footer' ? '0' : '24px',
          width: '100%',
        }}
        variant="primary"
        onClick={handleSubscription}
        loading={loading}
        disabled={loading || success}
      >
        {!success && loading ? 'Signing Up' : !success ? 'Sign Up' : 'Done'}
      </Button>
      <style jsx>
        {`
          ::placeholder {
            color: #6a6a6a;
          }

          input {
            height: 48px;
          }
        `}
      </style>
    </>
  )

  if (style === 'column') {
    return <div className="relative flex flex-col gap-12">{renderChildren()}</div>
  }

  return (
    <>
      <div className="grid grid-cols-3 gap-8 relative">{renderChildren()}</div>
      {message && <div className="mt-16 body text-black">{message}</div>}
    </>
  )
}

export default SubscribeForm
